import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DrawingIcon from 'mdi-react/DrawIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { PluginContext } from '../stores/PluginContext';
import { initWhiteBoard } from './WhiteBoard';
import { Grid } from '@material-ui/core';
import pic from '../../public/lock_whiteboard.png';

export default function WhiteBoardDialog() {
  const [open, setOpen] = React.useState(false);
  const {
    options,
    userPluginOwner,
    setOptions,
    prevBoards,
    activePlugin,
    togglePlugin,
    setActivePluginInfo,
    removeBoards,
  } = useContext(PluginContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={handleClickOpen}
        className={`px-3 py-2 w-full text-base flex items-center hover:text-white ${
          activePlugin == 'WHITEBOARD'
            ? 'bg-red-500 text-white'
            : 'hover:bg-indigo-500 '
        }`}
      >
        <DrawingIcon className="inline mr-1" />
        <span>WhiteBoard</span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        style={{ margin: '2rem', borderColor: 'black' }}
      >
        {prevBoards && prevBoards.length > 0 ? (
          <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
            {' '}
            Select WhiteBoard to Share
          </DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
            {' '}
            Share White Board
          </DialogTitle>
        )}
        {prevBoards && prevBoards.length > 0 ? (
          <DialogContent>
            <DialogContentText>
              You can choose any one of the whiteboards you have created in this
              session.
            </DialogContentText>

            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              style={{ minHeight: '220px' }}
            >
              {prevBoards.map((board, key) => (
                <Grid
                  item
                  xs={4}
                  container
                  justify="center"
                  direction="column"
                  style={{ position: 'relative' }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleClose();
                      setActivePluginInfo('WHITEBOARD');
                      setOptions({ ...board });
                    }}
                    style={{ padding: '3rem' }}
                  >
                    {' '}
                    White Board{' ' + (key + 1)}
                  </Button>
                  <IconButton
                    size="small"
                    style={{ position: 'absolute', top: '15px', right: '15px' }}
                    onClick={e => {
                      e.preventDefault();
                      removeBoards(board);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ minHeight: '300px' }}>
            <Grid container>
              <Grid item container xs={12} justify="center">
                <DialogContentText>
                  You are Sharing a whiteboard which will be visible to everyone
                  in the room!
                </DialogContentText>
              </Grid>
              <Grid item container xs={12} justify="center">
                <DialogContentText>
                  You can Choose to lock whiteboard, if you don't wish anyone to
                  write while you are writing on whiteboard
                </DialogContentText>
              </Grid>
              <Grid item container xs={12} justify="center">
                <img src={pic} alt="Lock whiteboard" width="200px"></img>
              </Grid>
            </Grid>

            <Grid container justify="center" spacing={3}>
              {prevBoards.map((board, key) => (
                <Grid item xs={4} container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setActivePluginInfo('WHITEBOARD');
                      setOptions({ ...board });
                    }}
                  >
                    {' '}
                    Share Board{' ' + key}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          {prevBoards && prevBoards.length > 0 && (
            <Button
              variant="outlined"
              style={{ color: 'red', borderColor: 'red' }}
              onClick={() => {
                removeBoards();
              }}
            >
              Remove All Whiteboards
            </Button>
          )}
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              togglePlugin('WHITEBOARD');
            }}
            color="primary"
            variant="contained"
          >
            {prevBoards && prevBoards.length > 0 ? 'New Board +' : 'Share'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { Layout, Button, Modal, notification, Spin } from 'antd';
const { confirm, warning } = Modal;
const { Header, Content, Sider } = Layout;
import MediaSettings from './settings';
import ChatFeed from './chat/index';
import Message from './chat/message';
import bLogo from '../public/100ms-logo.png';
import { AppContextProvider, AppContext } from './stores/AppContext';
import '../styles/css/app.scss';
import { retry, retryCallback } from './utils/retry';
import LoginForm from './LoginForm';
import Conference from './Conference';
import {
  HMSClient,
  HMSPeer,
  HMSClientConfig,
  HMSAnalyticsEventLevel,
} from '@100mslive/hmsvideo-web';
import { ENVS, ROLES } from './constants';
import { dependencies } from '../package.json';
import { getRequest, updateInputDevices } from './utils';
import { PluginContext, PluginContextProvider } from './stores/PluginContext';
import LogRocket from 'logrocket';

const sdkVersion = dependencies['@100mslive/hmsvideo-web'].substring(1);
console.info(`Using hmsvideo-web SDK version ${sdkVersion}`);
const sdkVersionVirtualBackground = dependencies[
  '@100mslive/hms-virtual-background'
].substring(1);
console.info(
  `Using hms-virtual-background SDK version ${sdkVersionVirtualBackground}`
);

const modes = {
  GALLERY: 'GALLERY',
  PINNED: 'PINNED',
};

async function getToken({ room_id, user_name, role = 'guest', env }) {
  const endpoint = process.env.TOKEN_ENDPOINT;
  const { token } = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({ room_id, user_name, env, role }),
  })
    .then(response => response.json())
    .catch(err => console.log('[App] Error client token: ', err));
  return token;
}

class OldAppUI extends React.Component {
  constructor(props) {
    super(props);
    props.setClient(null);
    props.setRoomState({
      isConnected: false,
    });
    this.state = {
      streams: [],
      streamInfo: [],
      mode: this.props.plugin ? modes.PINNED : modes.GALLERY,
      pinned: false,
    };
    if (!props.settings.codec) {
      props.setSettings({
        audioDevices: [],
        videoDevices: [],
        audioOutputDevices: [],
        selectedAudioDevice: '',
        selectedVideoDevice: '',
        resolution: 'qvga',
        bandwidth: 100,
        codec: 'vp8',
        frameRate: 20,
        isDevMode: true,
      });
    }

    navigator.mediaDevices.ondevicechange = () => this.onMediaDeviceChange();
  }

  onMediaDeviceChange = () => {
    console.log("selectedAudioDevice is ", this.props.settings.selectedAudioDevice);
    console.log("selectedVideoDevice is ", this.props.settings.selectedVideoDevice);
    console.log("Client is ", this.props.client);
    updateInputDevices().then(data => this.changeMediaSettings(data));
  };

  changeMediaSettings = (data) => {
    console.log("data after updation is ", data);
    for (let i = 0; i < data.audioDevices.length; i++) {
      if (data.audioDevices[i].deviceId != 'default' && data.audioDevices[i].deviceId != 'communications') {
        this.props.setSettings({ selectedAudioDevice: data.audioDevices[i].deviceId }, async () => {
          console.log("selectedAudioDevice changed ", data.audioDevices[i].deviceId);
          if (this.props.client != null) {
            console.log("Applying audio Constraints to client");
            await this.props.client.applyConstraints({
              advancedMediaConstraints: {
                audio: {
                  deviceId: data.audioDevices[i].deviceId,
                }
              }
            }, this.props.client.local);
          }
        });

        break;
      }
    }
    for (let i = 0; i < data.videoDevices.length; i++) {
      if (data.videoDevices[i].deviceId != 'default' && data.videoDevices[i].deviceId != 'communications') {
        this.props.setSettings({ selectedVideoDevice: data.videoDevices[i].deviceId }, async () => {
          console.log("selectedVideoDevice changed ", data.videoDevices[i].deviceId);
          if (this.props.client != null) {
            console.log("Applying video Constraints to client");
            await this.props.client.applyConstraints({
              resolution: this.props.settings.resolution,
              advancedMediaConstraints: {
                video: {
                  deviceId: data.videoDevices[i].deviceId,
                }
              }
            }, this.props.client.local);
          }
        });

        break;
      }
    }

    this.props.setSettings({
      videoDevices: data.videoDevices,
      audioDevices: data.audioDevices,
      audioOutputDevices: data.audioOutputDevices,
    });
  }

  _cleanUp = async (shouldRedirectToHome = true) => {
    if (shouldRedirectToHome) {
      window.history.pushState(
        {},
        '100ms',
        `${window.location.protocol}//${window.location.host}`
      );

      this.conference && (await this.conference.cleanUp());
      this.props.client && (await this.props.client.disconnect());
      this.props.setClient(null);
      this.props.setRoomState({
        isConnected: false,
        login: false,
      });
    } else {
      window.location.reload();
    }
  };

  _notification = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: 'bottomRight',
    });
  };

  _createClient = async ({ userName, env, roomId, role }) => {
    let url = `wss://${env}.${process.env.SFU_HOST || window.location.host}`;
    let authToken = await getToken({
      env,
      room_id: roomId,
      user_name: userName,
      role,
    });

    console.log(`%c[App] TOKEN IS: ${authToken}`, 'color: orange');

    console.log('[App] Websocket URL', url);

    try {
      let peer = new HMSPeer(
        userName,
        authToken,
        JSON.stringify({ key1: 'value1', key2: 'value2' })
      );

      let config = new HMSClientConfig({
        endpoint: url,
        analyticsEventLevel: HMSAnalyticsEventLevel.VERBOSE,
      });

      return new HMSClient(peer, config);
    } catch (err) {
      console.error('ERROR: ', err);
      alert('Invalid token');
    }
  };

  _handleJoin = async () => {
    this.props.setRoomState({
      loading: true,
    });
    this.hideMessage = () => { };
    //TODO this should reflect in initialization as well

    ![ROLES.LIVE_RECORD, ROLES.VIEWER].includes(this.role) &&
      this._onMediaSettingsChanged(
        this.props.settings.audioDevices,
        this.props.settings.videoDevices,
        this.props.settings.audioOutputDevices,
        this.props.settings.selectedAudioDevice,
        this.props.settings.selectedVideoDevice,
        this.props.settings.resolution,
        this.props.settings.bandwidth,
        this.props.settings.codec,
        this.props.settings.frameRate,
        this.props.settings.isDevMode
      );

    let client = await this._createClient({
      userName: this.props.loginInfo.displayName,
      env: this.props.loginInfo.env,
      roomId: this.props.loginInfo.roomId,
      role: this.props.loginInfo.role,
    });
    client.connect().catch(error => {
      alert(error.message);
    });

    window.onunload = async () => {
      await this._cleanUp();
    };

    client.on('peer-join', (room, peer) => {
      this._notification('Peer Join', `peer => ${peer.name} joined ${room}!`);
    });

    client.on('peer-leave', (room, peer) => {
      this._notification('Peer Leave', `peer => ${peer.name} left ${room}!`);
    });

    client.on('connect', () => {
      if (this.props.roomState.isConnected) return;
      console.log('[App] connected!');
      this._handleTransportOpen();
    });

    client.on('disconnect', reason => {
      console.log('[App] disconnected!');
      if (reason === 'ERR_CONNECTION_REFUSED') {
        warning({
          title: 'Unable to join room',
          content: 'Please check your internet connection or room id',
          onOk: async () => {
            this.props.setRoomState({
              loading: false,
            });
          },
        });
      }
    });

    client.on('stream-add', (room, peer, streamInfo) => {
      console.log(
        '[App] stream-add',
        JSON.stringify({ room, peer, streamInfo }, null, 2)
      );
      this._handleAddStream(room, peer, streamInfo);
    });

    client.on('stream-remove', (room, peer, streamInfo) => {
      console.log(
        '[App] stream-remove',
        JSON.stringify({ room, peer, streamInfo }, null, 2)
      );
      this._handleRemoveStream(room, peer, streamInfo);
    });

    client.on('broadcast', (room, peer, message) => {
      console.log('[App] broadcast: ', room, peer.name, message);
      if (message.type && message.type.startsWith('PLUGIN')) {
        return;
      }
      this._onMessageReceived(peer.name, message);
    });

    client.on('disconnected', async () => {
      console.log(`%c[APP] TEARING DOWN`, 'color:#fc0');
      location.reload();
    });

    this.props.setClient(client);
  };

  _handleTransportOpen = async () => {
    this.props.setRoomState({
      isConnected: true,
    });
    try {
      await this.props.client.join(this.props.loginInfo.roomId).catch(error => {
        console.log('[app] JOIN ERROR:', error);
      });
      let redirectURL = `${window.location.protocol}//${window.location.host}/?room=${this.props.loginInfo.roomId}&env=${this.props.loginInfo.env}&role=${this.props.loginInfo.role}`;

      window.history.pushState({}, '100ms', redirectURL);

      this.props.setRoomState({
        login: true,
        loading: false,
      });

      this._notification(
        'Connected!',
        `Welcome to the ${this.props.loginInfo.roomName || '100ms'} room => ${this.props.loginInfo.roomId
        }`
      );

      if (process.env.NODE_ENV == 'production') {
        LogRocket.identify(this.props.client.uid, {
          name: this.props.loginInfo.displayName,
          env: this.props.loginInfo.env,
        });
      }

      // Local video & audio are disabled for the 'live-record'
      // and 'viewer' roles. Their local stream is also not published.
      if (
        ![ROLES.LIVE_RECORD, ROLES.VIEWER].includes(this.props.loginInfo.role)
      ) {
        await this.conference.handleLocalStream();
      }
    } catch (error) {
      console.error('HANDLE THIS ERROR: ', error);
    }
  };

  _handleLeave = async () => {
    let this2 = this;
    confirm({
      title: 'Leave Now?',
      content: 'Do you want to leave the room?',
      async onOk() {
        await this2._cleanUp();
        this2.props.setRoomState({ login: false });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  _handleAudioTrackEnabled = enabled => {
    this.props.setRoomState({
      localAudioEnabled: enabled,
    });
    this.conference.muteMediaTrack('audio', enabled);
  };

  _handleVideoTrackEnabled = enabled => {
    this.props.setRoomState({
      localVideoEnabled: enabled,
    });
    this.conference.muteMediaTrack('video', enabled);
  };

  _handleScreenSharing = enabled => {
    this.props.setRoomState({
      screenSharingEnabled: enabled,
    });
    this.conference.handleScreenSharing(enabled);
  };

  _onRef = ref => {
    this.conference = ref;
  };

  _openOrCloseLeftContainer = collapsed => {
    this.props.setRoomState({
      collapsed: collapsed,
      hasUnreadMessages: false,
    });
  };

  _onMediaSettingsChanged = (
    audioDevices,
    videoDevices,
    audioOutputDevices,
    selectedAudioDevice,
    selectedVideoDevice,
    resolution,
    bandwidth,
    codec,
    frameRate,
    isDevMode,
    reloadPage = false
  ) => {
    this.props.setSettings({
      audioDevices,
      videoDevices,
      audioOutputDevices,
      selectedAudioDevice,
      selectedVideoDevice,
      resolution,
      bandwidth,
      codec,
      frameRate,
      isDevMode,
    });
    const constraints = {
      frameRate: frameRate,
      bitrate: bandwidth,
      resolution: resolution,
      advancedMediaConstraints: {
        audio: {
          deviceId: selectedAudioDevice,
        },
        video: {
          deviceId: selectedVideoDevice,
        },
      },
    };
    if (reloadPage) {
      this.props.client &&
        this.props.client.applyConstraints(
          constraints,
          this.props.client.local
        );
    }
  };

  _onMessageReceived = (from, message) => {
    console.log('[App] Received message:' + from + ':' + message);
    let messages = this.props.roomState.messages;
    let uid = 1;
    messages.push(new Message({ id: uid, message: message, senderName: from }));
    let hasUnreadMessages = false;
    if (this.props.roomState.collapsed) {
      hasUnreadMessages = true;
    }
    this.props.setRoomState({ messages, hasUnreadMessages });
  };

  _onSendMessage = data => {
    console.log('[App] Send message:' + data);
    var info = {
      senderName: this.props.loginInfo.displayName,
      msg: data,
    };
    this.props.client.broadcast(info, this.props.client.rid);
    let messages = this.props.roomState.messages;
    let uid = 0;
    messages.push(new Message({ id: uid, message: data, senderName: 'me' }));
    this.props.setRoomState({ messages });
  };

  isValidParams() {
    const validRoomPattern = /^[a-zA-Z0-9-.:_]*$/g;
    const validRoles = Object.values(ROLES);
    const validEnvs = Object.values(ENVS);
    try {
      const params = getRequest();

      if (params.role && !validRoles.includes(params.role.toLowerCase())) {
        return [false, 'Role'];
      } else if (params.env && !validEnvs.includes(params.env.toLowerCase())) {
        return [false, 'environment'];
      } else if (params.room && !validRoomPattern.test(params.room)) {
        return [false, 'Room ID'];
      } else {
        return [true, null];
      }
    } catch (error) {
      if (error instanceof URIError) {
        return [false, 'URL'];
      }
    }
  }

  tuneLocalStream = participantCount => {
    const bitrateBreakpoint = 6;
    if (participantCount >= bitrateBreakpoint) {
      if (this.bitrate !== 100) {
        this.bitrate = 100;
        this.props.client.applyConstraints(
          { bitrate: this.bitrate },
          this.props.client.local
        );
      }
      return;
    }
    if (this.bitrate === 100) {
      this.bitrate = this.props.settings.bandwidth;
      this.props.client.applyConstraints(
        { bitrate: this.bitrate },
        this.props.client.local
      );
    }
  };

  _handleAddStream = async (room, peer, streamInfo) => {
    if (streamInfo.description == 'IGNORE') return;
    const { client } = this.props;
    let streams = [];
    try {
      const sub = client.subscribe.bind(client);
      let stream = await retry(
        5,
        () =>
          retryCallback(
            5,
            sub,
            100,
            streamInfo.mid,
            room,
            'Subscribe Timed Out'
          ),
        100,
        'Subscribe Timed Out'
      );
      console.log(`Got stream for ${peer.name}`, stream);
      stream.info = { name: peer.name }; // @NOTE: Just because stream is expected to have info in this format at the moment by the UI
      console.log('IN _handleAddStream: ', peer);
      if ((this.state.streamInfo, stream.mid)) {
        streams.push({
          mid: stream.mid,
          stream,
          sid: stream.mid,
          screenshare: streamInfo.screen,
          ...this.state.streamInfo[stream.mid],
        });
        console.log('IN_handleAddStream_IN_IF_PART');
      } else {
        streams.push({ mid: stream.mid, stream, sid: streamInfo.mid });
        console.log('IN_handleAddStream_IN_ELSE_PART');
      }

      console.log(streams);

      this.setState(
        prevState => {
          return { streams: [...prevState.streams, ...streams] };
        },
        () => {
          console.log('FINAL STREAMS IN STATE ARE', this.state.streams);
        }
      );
    } catch (error) {
      console.debug(`ERROR: Error in subscribing`, error.message);
    }
  };

  _handleRemoveStream = async (room, peer, streamInfo) => {
    this.setState(prevState => {
      let streams = [...prevState.streams];
      let filteredStreams = streams.filter(item => item.sid !== streamInfo.mid);

      console.log('IN _handleRemoveStream FINAL streams are ', filteredStreams);

      return { streams: filteredStreams };
    });

    if (
      this.state.mode === modes.PINNED &&
      this.state.pinned === streamInfo.mid
    ) {
      this.setState({
        mode: modes.GALLERY,
      });
    }
  };

  setStreams = streams => {
    this.setState({ streams: streams });
  };

  setStreamInfo = streamsMap => {
    this.setState({ streamInfo: streamsMap });
  };

  setMode = mode => {
    this.setState({ mode: mode });
  };

  setPinned = pinned => {
    this.setState({ pinned: pinned });
  };

  render() {
    const {
      login,
      loading,
      localAudioEnabled,
      localVideoEnabled,
      screenSharingEnabled,
      collapsed,
      vidFit,
    } = this.props.roomState;

    const isValidParams = this.isValidParams()[0];

    return (
      <Layout className="app-layout">
        <Header
          className="app-header"
          style={{
            backgroundColor: '#0B0F15',
            zIndex: '10',
            padding: '0 0',
            margin: '0 auto',
            width: '100%',
          }}
        >
          <div className="app-header-left">
            <a href="https://100ms.live/" target="_blank">
              <img src={bLogo} className="h-8" />
            </a>
          </div>
          {this.props.loginInfo.role.toLowerCase() !== ROLES.VIEWER && (
            <div className="app-header-right">
              <MediaSettings
                onMediaSettingsChanged={this._onMediaSettingsChanged}
                settings={this.props.settings}
                isLoggedIn={login}
                setLocalStreamError={this.props.setLocalStreamError}
              />
            </div>
          )}
        </Header>

        <Content className="app-center-layout">
          {!isValidParams ? (
            <div
              className="min-h-screen flex items-center justify-center w-full py-8 px-4 sm:px-6 lg:px-8"
              style={{ backgroundColor: '#0B0F15' }}
            >
              <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 py-5 p-6 bg-gray-100 my-3">
                <div className="">
                  <h2 className="mt-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    100ms Conference
                  </h2>

                  <p className="mt-2 text-center text-sm leading-5 text-gray-600 mb-2">
                    The requested {this.isValidParams()[1]} is invalid. Please
                    verify your credentials.
                  </p>

                  <button
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none transition duration-150 ease-in-out"
                    onClick={() => {
                      this._cleanUp();
                      location.reload();
                    }}
                  >
                    Back to Home
                  </button>
                </div>
              </div>
            </div>
          ) : login ? (
            <Layout className="app-content-layout">
              <Sider
                width={320}
                collapsedWidth={0}
                trigger={null}
                collapsible
                collapsed={collapsed}
                style={{
                  backgroundColor: '#0B0F15',
                  height: 'calc(100vh - 4rem)',
                }}
              >
                <div className="left-container">
                  <ChatFeed
                    messages={this.props.roomState.messages}
                    onSendMessage={this._onSendMessage}
                    onClose={() => this._openOrCloseLeftContainer(!collapsed)}
                  />
                </div>
              </Sider>
              <Layout
                className="app-right-layout"
                style={{ height: 'calc(100vh - 64px)' }}
              >
                <Content style={{ flex: 1, position: 'relative' }}>
                  <div>
                    <AppContext.Consumer>
                      {context => (
                        <PluginContextProvider client={context.client}>
                          <PluginContext.Consumer>
                            {pluginContext => (
                              <Conference
                                roomName={this.props.loginInfo.roomName}
                                roomId={this.props.loginInfo.roomId}
                                collapsed={this.props.roomState.collapsed}
                                client={context.client}
                                settings={context.settings}
                                localAudioEnabled={localAudioEnabled}
                                localVideoEnabled={localVideoEnabled}
                                vidFit={vidFit}
                                loginInfo={this.props.loginInfo}
                                ref={ref => {
                                  this.conference = ref;
                                }}
                                onScreenToggle={() =>
                                  this._handleScreenSharing(
                                    !screenSharingEnabled
                                  )
                                }
                                onLeave={this._handleLeave}
                                onChatToggle={() => {
                                  this._openOrCloseLeftContainer(!collapsed);
                                }}
                                isChatOpen={!this.props.roomState.collapsed}
                                cleanUp={this._cleanUp}
                                role={this.props.loginInfo.role}
                                hasUnreadMessages={
                                  this.props.roomState.hasUnreadMessages
                                }
                                setLocalStreamError={
                                  this.props.setLocalStreamError
                                }
                                plugin={pluginContext.name}
                                userPluginOwner={pluginContext.userPluginOwner}
                                streams={this.state.streams}
                                streamInfo={this.state.streamInfo}
                                mode={this.state.mode}
                                pinned={this.state.pinned}
                                setStreams={this.setStreams}
                                setStreamInfo={this.setStreamInfo}
                                setMode={this.setMode}
                                setPinned={this.setPinned}
                                audioPermission={
                                  context.roomState.audioPermission
                                }
                                videoPermission={
                                  context.roomState.videoPermission
                                }
                              />
                            )}
                          </PluginContext.Consumer>
                        </PluginContextProvider>
                      )}
                    </AppContext.Consumer>
                  </div>
                </Content>
              </Layout>
            </Layout>
          ) : loading ? (
            <div
              className="flex items-center justify-center"
              style={{ height: 'calc(100vh - 64px)' }}
            >
              <Spin size="large" tip="Connecting..." />
            </div>
          ) : (
            <div className="relative w-full">
              <AppContext.Consumer>
                {context => (
                  <LoginForm
                    settings={context.settings}
                    loginInfo={context.loginInfo}
                    setSettings={context.setSettings}
                    setLoginInfo={context.setLoginInfo}
                    handleLogin={this._handleJoin}
                    createClient={this._createClient}
                    client={context.client}
                    setClient={context.setClient}
                    roomState={context.roomState}
                    setRoomState={context.setRoomState}
                    setLocalStreamError={this.props.setLocalStreamError}
                    setVideoPermission={this.props.setVideoPermission}
                    setAudioPermission={this.props.setAudioPermission}
                  />
                )}
              </AppContext.Consumer>
            </div>
          )}
          {this.props.localStreamError && (
            <Modal
              visible={!!this.props.localStreamError}
              title={this.props.localStreamError.title}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this._cleanUp(false);
                  }}
                >
                  Try Again
                </Button>,
              ]}
            >
              <p>{this.props.localStreamError.message}</p>
            </Modal>
          )}
        </Content>
      </Layout>
    );
  }
}

class OldApp extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {context => (
          <PluginContextProvider client={context.client}>
            <PluginContext.Consumer>
              {pluginContext => (
                <OldAppUI
                  settings={context.settings}
                  roomState={context.roomState}
                  loginInfo={context.loginInfo}
                  setSettings={context.setSettings}
                  setLoginInfo={context.setLoginInfo}
                  setRoomState={context.setRoomState}
                  setClient={context.setClient}
                  client={context.client}
                  localStreamError={context.localStreamError}
                  setLocalStreamError={context.setLocalStreamError}
                  setAudioPermission={context.setAudioPermission}
                  setVideoPermission={context.setVideoPermission}
                  plugin={pluginContext.name}
                />
              )}
            </PluginContext.Consumer>
          </PluginContextProvider>
        )}
      </AppContext.Consumer>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <AppContextProvider>
        <OldApp />
      </AppContextProvider>
    );
  }
}

export default App;

import React from 'react';
import Layout from '../components/Plugins/Layout';

export const initializeMiroBoard = () => {
  miroBoardsPicker.open({
    clientId: process.env.MIRO_CLIENT_ID,
    action: 'select',
    success: function (result) {
      console.log(result);
    },
  });
};

export default function MiroBoard() {
  return (
    <Layout>
      <h1 className="text-3xl text-white">MiroBoard</h1>
    </Layout>
  );
}

import { notification } from 'antd';
const pause = duration => {
  return new Promise(resolve => setTimeout(resolve, duration));
};

const refresh = message => {
  notification.info({
    message: message,
    description: message + ' Refreshing.',
    placement: 'bottomRight',
  });
  setTimeout(() => window.location.reload(), 5000);
};

const showNotification = message => {
  notification.info({
    message: message,
    description: message + ' Reconnecting.',
    placement: 'bottomRight',
  });
};

const retry = async (retryTimes, func, delay, message) => {
  if (retryTimes < 5) {
    console.log('Retrying: ', delay, message, retryTimes);
    showNotification(message);
  }
  return await func().catch(async err => {
    console.error(err);
    if (err.canRetry) {
      if (retryTimes > 0) {
        return pause(delay).then(
          async () => await retry(retryTimes - 1, func, delay * 2, message)
        );
      } else {
        refresh(message);
      }
    }
  });
};

const retryCallback = async (
  retryTimes,
  func,
  delay,
  argument1,
  argument2,
  message
) => {
  if (retryTimes < 5) {
    console.log('Retry callback: ', delay, message, retryTimes);
    showNotification(message);
  }
  if (retryTimes > 0) {
    return await func(argument1, argument2, async error => {
      console.error(error);
      if (error.canRetry) {
        return pause(delay).then(
          async () =>
            await retryCallback(
              retryTimes - 1,
              func,
              delay * 2,
              argument1,
              argument2,
              message
            )
        );
      } else refresh(message);
    });
  } else {
    return refresh(message);
  }
};

export { retry, retryCallback };

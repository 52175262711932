import React from 'react';
import { Button, Modal, notification } from 'antd';
import { Controls } from './components/Controls';
import '../styles/css/conference.scss';
import { Gallery } from './components/Conference/gallery';
import { Pinned } from './components/Conference/pinned';
import PeerState, { onRoomStateChange } from './utils/state';
import { getLocalStreamException } from './utils';
import { AppContext } from './stores/AppContext';
import { LocalStream } from '@100mslive/hmsvideo-web';
import { retry, retryCallback } from './utils/retry';

const models = {
  model96: '/models/segm_lite_v681.tflite',
  model144: '/models/segm_full_v679.tflite',
};

const modes = {
  GALLERY: 'GALLERY',
  PINNED: 'PINNED',
};

class Conference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localStream: null,
      localScreen: null,
      audioMuted: false,
      videoMuted: false,
      isAudioPermitted: true,
      isVideoPermitted: true,
      isBlur: false,
      isBlurImage: false,
      blurImage: null,
      mode: modes.GALLERY,
      pinned: false,
      localStreamError: null,
    };
  }

  componentDidMount = () => {
    // setTimeout (() => {
    console.log('INSIDE CONFERENCE.JSX COMPONENT DID MOUNT');
    const { client, setStreamInfo, setStreams, setMode } = this.props;
    this.roomStateUnsubscribe = onRoomStateChange(
      client.rid,
      peers => {
        const streamsMap = peers.reduce((a, c) => {
          return { ...a, ...c.streams };
        }, {});
        setStreamInfo(streamsMap);
        const newStreams = this.props.streams.map(stream => {
          return { ...stream, ...streamsMap[stream.mid] };
        });

        setStreams(newStreams);
      },

      console.error
    );

    if (this.props.plugin) setMode(modes.PINNED);
    // console.log("In conference DidMount selected audio device is ", this.props.settings.selectedAudioDevice);
    // }, 10000);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.plugin !== this.props.plugin) {
      if (this.props.plugin) {
        this.props.setMode(modes.PINNED);
        if (this.props.plugin === 'YOUTUBE') {
          this.muteMediaTrack('audio', false);
        }
      } else {
        this.props.setMode(modes.GALLERY);
        this.props.setPinned(null);
      }
    }
    // console.log("In conference DidUpdate selected audio device is ", this.props.settings.selectedAudioDevice);
  };

  updateLocalPeerState = () => {
    this.peerState = new PeerState({
      mid: this.state.localStream.mid,
      uid: this.props.client.uid,
      rid: this.props.client.rid,
    });

    this.peerState.update({
      audioEnabled: true,
      videoEnabled: true,
    });

    this.peerState.onRequest(request => {
      let remotePeer;

      this.props.streams.forEach(item => {
        if (item.uid === request.from) remotePeer = item.stream.info.name;
      });

      const isMuted = this.state.audioMuted;
      if (request.mute) {
        if (isMuted) return;
        this.muteMediaTrack('audio', false);
        this._notification('MUTE REQUEST', `${remotePeer} muted you`);
      } else {
        if (!isMuted) return;
        this.muteMediaTrack('audio', true);
        this._notification('UNMUTE REQUEST', `${remotePeer} unmuted you`);
      }
    });
  };

  componentWillUnmount = () => {
    const { client } = this.props;
    if (client) {
      client.off('stream-add', this._handleAddStream);
      client.off('stream-remove', this._handleRemoveStream);
    }
    this.roomStateUnsubscribe && this.roomStateUnsubscribe();
  };

  cleanUp = async () => {
    let { localStream, localScreen } = this.state;
    await this.setState({ localStream: null, localScreen: null });
    this.props.setStreams([]);

    // sorEach(async item => {
    //   await this.client.unsubscribe(item.stream,this.client.rid);
    // });
    await this._unpublish(localStream);
    await this._unpublish(localScreen);

    // this.peerStateUnsubscribe();
    this.peerState && this.peerState.delete();
  };

  tuneLocalStream = participantCount => {
    const bitrateBreakpoint = 6;
    if (participantCount >= bitrateBreakpoint) {
      if (this.bitrate !== 100) {
        this.bitrate = 100;
        this.props.client.applyConstraints(
          { bitrate: this.bitrate },
          this.props.client.local
        );
      }
      return;
    }
    if (this.bitrate === 100) {
      this.bitrate = this.props.settings.bandwidth;
      this.props.client.applyConstraints(
        { bitrate: this.bitrate },
        this.props.client.local
      );
    }
  };

  setIsBlur = isBlur => {
    this.setState(prevState => {
      return { isBlur: isBlur };
    });
  };

  setIsBlurImage = isBlurImage => {
    this.setState(prevState => {
      return { isBlurImage: isBlurImage };
    });
  };

  setBlurImage = blurImage => {
    this.setState(prevState => {
      return { blurImage: blurImage };
    });
  };

  _notification = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: 'bottomRight',
    });
  };

  _unpublish = async stream => {
    const { client } = this.props;
    if (stream) {
      await this._stopMediaStream(stream);
      await client.unpublish(stream, client.rid);
    }
  };

  muteMediaTrack = (type, enabled) => {
    let { localStream } = this.state;
    if (!localStream) {
      return;
    }
    if (enabled) {
      localStream.unmute(type);
    } else {
      localStream.mute(type);
    }
    if (
      (!this.props.videoPermission && type === 'video') ||
      (!this.props.audioPermission && type === 'audio')
    ) {
      return;
    }

    if (type === 'audio') {
      this.setState({ audioMuted: !enabled });
      this.peerState && this.peerState.update({ audioEnabled: enabled });
    } else if (type === 'video') {
      this.setState({ videoMuted: !enabled });
      this.peerState && this.peerState.update({ videoEnabled: enabled });
    }
  };

  _muteAllParticipants = () => {
    this.muteMediaTrack('audio', false);
    this.props.streams.forEach(stream => {
      this._onRequest(stream.uid, { mute: stream.audioEnabled });
    });
  };

  handleLocalStream = async () => {
    const {
      client,
      settings,
      localVideoEnabled,
      localAudioEnabled,
    } = this.props;
    client
      .getLocalStream({
        codec: settings.codec.toUpperCase(),
        resolution: settings.resolution,
        bitrate: settings.bandwidth,
        frameRate: settings.frameRate,
        // We always get the audio/video track but mute/unmute based on the preference during preview
        shouldPublishVideo: this.props.videoPermission,
        shouldPublishAudio: this.props.audioPermission,
        advancedMediaConstraints: {
          video: {
            deviceId: settings.selectedVideoDevice,
          },
          audio: {
            deviceId: settings.selectedAudioDevice,
            advanced: [
              { googEchoCancellation: { exact: true } },
              { googExperimentalEchoCancellation: { exact: true } },
              { autoGainControl: { exact: true } },
              { noiseSuppression: { exact: true } },
              { googHighpassFilter: { exact: true } },
              { googAudioMirroring: { exact: true } },
            ],
          },
        },
      })
      .then(async localStream => {
        localStream.getAudioTracks().forEach(track => {
          if ('contentHint' in track) {
            track.contentHint = 'speech';
          }
        });
        let black = ({ width = 640, height = 480 } = {}) => {
          let canvas = Object.assign(document.createElement('canvas'), {
            width,
            height,
          });
          canvas.getContext('2d').fillRect(20, 15, width, height);
          let stream = canvas.captureStream();
          return Object.assign(stream.getVideoTracks()[0], { enabled: false });
        };
        let silence = () => {
          let ctx = new AudioContext(),
            oscillator = ctx.createOscillator();
          let dst = oscillator.connect(ctx.createMediaStreamDestination());
          oscillator.start();
          return Object.assign(dst.stream.getAudioTracks()[0], {
            enabled: false,
          });
        };
        if (localStream.getVideoTracks().length == 0) {
          localStream.addTrack(black());
        }
        if (localStream.getAudioTracks().length == 0) {
          localStream.addTrack(silence());
        }
        const pub = client.publish.bind(client);
        return await retry(
          5,
          () =>
            retryCallback(
              5,
              pub,
              100,
              localStream,
              client.rid,
              'Publish Timed Out. Refreshing'
            ),
          100,
          'Publish Timed Out. Refreshing'
        );
      })
      .then(async localStream => {
        this.setState({ localStream }, () => {
          console.log(localStream);
          this.updateLocalPeerState();
        });
        if (!this.props.videoPermission || !localVideoEnabled) {
          this.setState({ videoMuted: !this.state.videoMuted });
          localStream.mute('video');
          this.peerState &&
            this.peerState.update({ videoEnabled: this.state.videoMuted });
        }
        if (!this.props.audioPermission || !localAudioEnabled) {
          this.setState({ audioMuted: !this.state.audioMuted });
          localStream.mute('audio');
          this.peerState &&
            this.peerState.update({ audioEnabled: this.state.audioMuted });
        }
      })
      .catch(async error => {
        if (error.name == 'TypeError') {
          console.error(error);
          let silence = () => {
            let ctx = new AudioContext(),
              oscillator = ctx.createOscillator();
            let dst = oscillator.connect(ctx.createMediaStreamDestination());
            oscillator.start();
            return Object.assign(dst.stream.getAudioTracks()[0], {
              enabled: false,
            });
          };

          let black = ({ width = 640, height = 480 } = {}) => {
            let canvas = Object.assign(document.createElement('canvas'), {
              width,
              height,
            });
            canvas.getContext('2d').fillRect(20, 15, width, height);
            let stream = canvas.captureStream();
            return Object.assign(stream.getVideoTracks()[0], {
              enabled: false,
            });
          };

          let blackSilence = (...args) =>
            new MediaStream([black(...args), silence()]);
          const localStream = new LocalStream(
            blackSilence(),
            { codec: 'VP8', resolution: 'qvga', bandwidth: 512 },
            { audio: true, video: false }
          );
          localStream.getAudioTracks().forEach(track => {
            if ('contentHint' in track) {
              track.contentHint = 'speech';
            }
          });
          const pub = client.publish.bind(client);
          await retry(
            5,
            () =>
              retryCallback(
                5,
                pub,
                100,
                localStream,
                client.rid,
                'Publish Timed Out. Refreshing'
              ),
            100,
            'Publish Timed Out. Refreshing'
          );
          this.setState({ localStream }, () => {
            this.setState({ localStream }, () => {
              this.updateLocalPeerState();
            });
          });

          if (!this.props.videoPermission || !localVideoEnabled) {
            this.setState({ videoMuted: !this.state.videoMuted });
            localStream.mute('video');
            this.peerState &&
              this.peerState.update({ videoEnabled: this.state.videoMuted });
          }
          if (!this.props.audioPermission || !localAudioEnabled) {
            this.setState({ audioMuted: !this.state.audioMuted });
            this.peerState &&
              this.peerState.update({ audioEnabled: this.state.audioMuted });
            localStream.mute('audio');
          }
        } else {
          console.error(error);
          this.props.setLocalStreamError(error);
        }
      });
  };

  handleScreenSharing = async enabled => {
    let { localScreen } = this.state;
    const { client, settings } = this.props;
    if (enabled) {
      localScreen = await client.getLocalScreen({
        bitrate: 0,
        codec: settings.codec.toUpperCase(),
      });

      let track = localScreen.getVideoTracks()[0];
      track.addEventListener('ended', () => {
        this.handleScreenSharing(false);
      });

      console.log({ localScreen });
      const pub = client.publish.bind(client);
      await retry(
        5,
        () =>
          retryCallback(
            5,
            pub,
            100,
            localScreen,
            client.rid,
            'Publish Timed Out.'
          ),
        100,
        'Publish Timed Out.'
      );
    } else {
      if (localScreen) {
        this._unpublish(localScreen);
        localScreen = null;
        if (
          this.props.mode === modes.PINNED &&
          this.props.pinned === client.uid + '-screen'
        ) {
          this.props.setMode(modes.GALLERY);
          this.props.setPinned(null);
        }
      }
    }
    this.setState({ localScreen });
  };

  _stopMediaStream = async stream => {
    let tracks = stream.getTracks();
    for (let i = 0, len = tracks.length; i < len; i++) {
      await tracks[i].stop();
    }
  };

  _onRequest = (uid, request) => {
    this.peerState && this.peerState.setRequest(uid, request);
  };

  _onChangeVideoPosition = data => {
    let id = data.id;
    let index = data.index;
    console.log('_onChangeVideoPosition id:' + id + '  index:' + index);

    if (index == 0) {
      return;
    }

    const streams = this.props.streams;
    let first = 0;
    let big = 0;
    for (let i = 0; i < streams.length; i++) {
      let item = streams[i];
      if (item.mid == id) {
        big = i;
        break;
      }
    }

    let c = streams[first];
    streams[first] = streams[big];
    streams[big] = c;

    this.props.setStreams(streams);
  };

  switchToGallery = () => {
    this.props.setMode(modes.GALLERY);
    this.props.setPinned(null);
  };

  render = () => {
    const { client, role, plugin, streams, pinned } = this.props;
    const {
      localStream,
      localScreen,
      audioMuted,
      videoMuted,
      isBlur,
      isBlurImage,
      blurImage,
    } = this.state;
    const id = client ? client.uid : null;
    let videoCount = streams.length;
    if (localStream) videoCount++;
    if (localScreen) videoCount++;

    if (client)
      return (
        <>
          {this.props.mode === modes.PINNED ? (
            <Pinned
              streams={streams}
              audioMuted={audioMuted}
              videoMuted={videoMuted}
              isBlur={isBlur}
              isBlurImage={isBlurImage}
              blurImage={blurImage}
              videoCount={videoCount}
              localStream={localStream}
              localScreen={localScreen}
              client={client}
              id={id}
              loginInfo={this.props.loginInfo}
              pinned={pinned}
              plugin={plugin}
              onUnpin={this.switchToGallery}
              onRequest={this._onRequest}
            />
          ) : (
            <Gallery
              streams={streams}
              audioMuted={audioMuted}
              videoMuted={videoMuted}
              isBlur={isBlur}
              isBlurImage={isBlurImage}
              blurImage={blurImage}
              videoCount={videoCount}
              localStream={localStream}
              localScreen={localScreen}
              client={client}
              id={id}
              loginInfo={this.props.loginInfo}
              onPin={streamId => {
                this.props.setMode(modes.PINNED);
                this.props.setPinned(streamId);
              }}
              onRequest={this._onRequest}
            />
          )}
          <AppContext.Consumer>
            {context => (
              <Controls
                role={role}
                isMuted={this.state.audioMuted}
                isCameraOn={!this.state.videoMuted}
                screenSharingEnabled={context.roomState.screenSharingEnabled}
                onScreenToggle={this.props.onScreenToggle}
                onLeave={this.props.onLeave}
                onMicToggle={() => {
                  this.muteMediaTrack('audio', this.state.audioMuted);
                }}
                onCamToggle={() => {
                  this.muteMediaTrack('video', this.state.videoMuted);
                }}
                isBlur={isBlur}
                setIsBlur={this.setIsBlur}
                isBlurImage={isBlurImage}
                setIsBlurImage={this.setIsBlurImage}
                blurImage={blurImage}
                setBlurImage={this.setBlurImage}
                onChatToggle={this.props.onChatToggle}
                isChatOpen={this.props.isChatOpen}
                loginInfo={this.props.loginInfo}
                hasUnreadMessages={this.props.hasUnreadMessages}
                audioPermission={this.props.audioPermission}
                videoPermission={this.props.videoPermission}
              />
            )}
          </AppContext.Consumer>
        </>
      );
    return <></>;
  };
}
export default Conference;

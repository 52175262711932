import React, { useContext, useState } from 'react';
import { PluginContext } from '../../stores/PluginContext';
import { ControlButton } from './ControlButton';
import VideoIcon from 'mdi-react/VideoIcon';
import VideocamOffIcon from 'mdi-react/VideocamOffIcon';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import MicrophoneOffIcon from 'mdi-react/MicrophoneOffIcon';
import PhoneHangupIcon from 'mdi-react/PhoneHangupIcon';
import TelevisionIcon from 'mdi-react/TelevisionIcon';
import TelevisionOffIcon from 'mdi-react/TelevisionOffIcon';
import VideoCheckIcon from 'mdi-react/VideoCheckIcon';
import ToolShare from '../../ToolShare';
import BackgroundBlur from '../BackgroundBlur';
import PluginIcon from 'mdi-react/DrawingBoxIcon';

import { ROLES } from '../../constants';
import { Popover } from '@material-ui/core';

import WhiteBoardDialog from '../../plugins/WhiteBoardDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const PluginButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'plugin-popover' : undefined;
  const [confirmClose, setConfirmClose] = useState(false);

  const {
    name: activePlugin,
    togglePlugin,
    userPluginOwner,
    availablePlugins,
  } = useContext(PluginContext);

  const ActivePluginIcon =
    (activePlugin &&
      availablePlugins.find(plugin => plugin.name == activePlugin).icon) ||
    PluginIcon;

  const handleControlClick = event => {
    if (activePlugin) {
      // togglePlugin(activePlugin);
      setConfirmClose(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <div className="mx-1">
        <ControlButton
          icon={<PluginIcon className="text-indigo-100" />}
          activeIcon={<ActivePluginIcon className="text-red-100" />}
          label="Plugins"
          isActive={activePlugin}
          onClick={handleControlClick}
          disabled={!userPluginOwner()}
        />
      </div>
      {activePlugin && (
        <Dialog
          open={confirmClose}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullwidth={true}
        >
          <DialogTitle id="alert-dialog-title">Stop Sharing?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to stop sharing{' '}
              {
                availablePlugins.find(plugin => plugin.name == activePlugin)
                  .label
              }
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmClose(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setConfirmClose(false);
                togglePlugin(activePlugin);
              }}
              color="primary"
              autoFocus
              variant="contained"
            >
              Stop
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="py-3">
          {availablePlugins
            .filter(plugin => plugin.name !== 'WHITEBOARD')
            .map((plugin, index) => {
              const { name, label, icon: Icon } = plugin;
              return (
                <button
                  onClick={() => togglePlugin(name)}
                  className={`px-3 py-2 w-full text-base flex items-center hover:text-white ${
                    activePlugin == name
                      ? 'bg-red-500 text-white'
                      : 'hover:bg-indigo-500 '
                  }`}
                  key={index}
                >
                  <Icon className="inline mr-1" />
                  <span>{label}</span>
                </button>
              );
            })}
          <WhiteBoardDialog />
        </div>
      </Popover>
    </>
  );
};

const Controls = ({
  role,
  isMuted,
  isBlur,
  setIsBlur,
  isBlurImage,
  setIsBlurImage,
  blurImage,
  setBlurImage,
  screenSharingEnabled,
  isCameraOn,
  isChatOpen,
  onScreenToggle,
  onMicToggle,
  onCamToggle,
  onLeave,
  onChatToggle,
  loginInfo,
  hasUnreadMessages,
  audioPermission,
  videoPermission,
}) => {
  const isViewer = role === ROLES.VIEWER;
  const isLiveRecordingOn = role === ROLES.LIVE_RECORD;

  const cameraButton = (
    <div className="mr-1">
      <ControlButton
        icon={<VideoIcon className="text-indigo-100" />}
        activeIcon={<VideocamOffIcon className="text-red-100" />}
        label="Camera"
        isActive={!isCameraOn}
        onClick={onCamToggle}
        permission={videoPermission}
      />
    </div>
  );

  const blurButton = (
    <div className="mx-1">
      <BackgroundBlur
        isBlur={isBlur}
        setIsBlur={setIsBlur}
        isBlurImage={isBlurImage}
        setIsBlurImage={setIsBlurImage}
        blurImage={blurImage}
        setBlurImage={setBlurImage}
      />
    </div>
  );

  const micButton = (
    <div className="mx-1">
      <ControlButton
        icon={<MicrophoneIcon className="text-indigo-100" />}
        activeIcon={<MicrophoneOffIcon className="text-red-100" />}
        label="Mic"
        isActive={isMuted}
        onClick={onMicToggle}
        permission={audioPermission}
      />
    </div>
  );

  const leaveButton = (
    <div className="mx-1">
      <ControlButton
        icon={<PhoneHangupIcon className="text-red-100" />}
        activeIcon={<PhoneHangupIcon className="text-red-100" />}
        label="Leave"
        onClick={onLeave}
        isActive
      />
    </div>
  );

  const screenShareButton = (
    <div className="mx-1">
      <ControlButton
        icon={<TelevisionIcon className="text-indigo-100" />}
        activeIcon={<TelevisionOffIcon className="text-red-100" />}
        label="Screen"
        isActive={screenSharingEnabled}
        onClick={onScreenToggle}
      />
    </div>
  );

  const chatButton = (
    <div className="mx-1 relative">
      {hasUnreadMessages && (
        <div
          className="absolute -top-1 right-0 w-3 h-3 bg-red-500 rounded-full border-2"
          style={{ borderColor: 'rgb(26,22,25)' }}
        />
      )}
      <ControlButton
        icon={
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        }
        activeIcon={
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        }
        label="Chat"
        onClick={onChatToggle}
        isActive={isChatOpen}
      />
    </div>
  );

  const shareButton = (
    <div className="ml-1">
      <ToolShare url={location.href} />
    </div>
  );

  return (
    <div
      //className="h-16 absolute w-full  bottom-0 flex items-center py-1"
      className="h-16 p-2"
      style={{
        backgroundColor: '#0B0F15',
        position: 'absolute',
        width: '100%',
        bottom: '0px',
        left: '0px',
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px',
          flex: 1,
          // position: 'absolute',
          // left: '20px',
          // top: '20px',
          // width: '640px',
          // height: '52px',
        }}
      >
        <PluginButton />
        {shareButton}
        {chatButton}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',

          // position: 'absolute',
          // width: '124px',
          // height: '52px',
          // left: 'calc(50% - 124px/2)',
          // top: '20px',
        }}
      >
        {!isLiveRecordingOn && (
          <>
            {!isViewer && (
              <>
                {cameraButton}
                {micButton}
                {blurButton}
              </>
            )}
            {!isViewer && screenShareButton}
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0px',
          flex: 1,
          // position: 'absolute',

          // height: '52px',
          // right: '20px',
          // top: 'calc(50% - 52px/2)',
        }}
      >
        {leaveButton}
      </div>
    </div>
  );
};

export { Controls };

import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Card } from 'antd';
import BlurOnIcon from 'mdi-react/BlurOffIcon';
import UploadIcon from 'mdi-react/UploadIcon';
// import BlurOnIcon from './background.png';

import BlurOffIcon from 'mdi-react/BlurOnIcon';
import background from './white-background-icon.png';

import { ControlButton } from '../Controls/ControlButton';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './style.scss';

const images = [
  '/background-images/100ms-logo.png',
  '/background-images/space.jpeg',
  '/background-images/fun.jpeg',
  '/background-images/1.jpeg',
  '/background-images/2.jpeg',
  '/background-images/3.jpeg',
  // '/background-images/4.png',
  '/background-images/5.jpeg',
  '/background-images/6.jpeg',
  '/background-images/7.jpeg',
  '/background-images/8.jpeg',
  '/background-images/9.jpeg',
  '/background-images/10.jpeg',
  '/background-images/11.jpeg',
  '/background-images/12.jpeg',
  '/background-images/13.jpeg',
  // '/background-images/14.jpg',
  '/background-images/15.jpg',
  '/background-images/16.jpg',
  // '/background-images/17.jpg',
  // '/background-images/18.jpg',
  // '/background-images/19.jpg',
  // '/background-images/20.jpg',
  // '/background-images/21.jpg',
  '/background-images/21.jpeg',
  '/background-images/22.jpg',
  // '/background-images/22.jpeg',
];

const BackgroundBlur = props => {
  const {
    isBlur,
    setIsBlur,
    isBlurImage,
    setIsBlurImage,
    blurImage,
    setBlurImage,
  } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
  };
  const toggleModal = () => {
    setVisible(!visible);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const photoChangeHandler = event => {
    getBase64(event.target.files[0], imageUrl => {
      setImageUrl(imageUrl);
      setIsBlur(true);
      setIsBlurImage(true);
      setBlurImage(imageUrl);
    });
  };

  const uploadButton = (
    <div>
      <input
        span={6}
        type="file"
        name="file"
        id="file"
        accept="image/*"
        onChange={photoChangeHandler}
        className="inputfile"
      />
      <label htmlFor="file">
        <UploadIcon />
      </label>
    </div>
  );

  return (
    <>
      <ControlButton
        icon={<img src={background} className="text-indigo-100 w-7 h-7" />}
        activeIcon={<img src={background} className="text-red-100 w-7 h-7" />}
        label="Background"
        isActive={isBlur}
        onClick={toggleModal}
      />
      <Modal
        title="Choose Background"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Ok"
        cancelText="Cancel"
        footer={null}
        width="1160px"
      >
        <div className="modalui">
          <Card
            hoverable
            style={{ width: 100, height: 100, margin: 10 }}
            cover={<BlurOnIcon />}
            className={`w-full h-full flex justify-center items-center cursor-pointer ${
              !isBlur ? 'border-2 border-blue-400' : ''
            }`}
            onClick={() => {
              setIsBlur(false);
            }}
          ></Card>
          <Card
            hoverable
            style={{ width: 100, height: 100, margin: 10 }}
            cover={<BlurOffIcon />}
            className={`w-full h-full flex justify-center items-center cursor-pointer ${
              isBlur && !isBlurImage ? 'border-2 border-blue-400' : ''
            }`}
            onClick={() => {
              setIsBlur(true);
              setIsBlurImage(false);
            }}
          ></Card>
          <Card
            hoverable
            style={{ width: 100, height: 100, margin: 10 }}
            cover={uploadButton}
            className={`w-full h-full flex justify-center items-center cursor-pointer ${
              isBlur && !isBlurImage ? 'border-2 border-blue-400' : ''
            }`}
          ></Card>
          {images.map((image, index) => (
            <Card
              key={index}
              hoverable
              style={{
                width: 100,
                height: 100,
                margin: 10,
                background: 'black',
              }}
              cover={<img src={image} />}
              className={`w-full h-full flex justify-center items-center cursor-pointer ${
                isBlur && isBlurImage && blurImage === image
                  ? 'border-2 border-blue-400'
                  : ''
              }`}
              onClick={() => {
                setIsBlur(true);
                setIsBlurImage(true);
                setBlurImage(image);
              }}
            ></Card>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default BackgroundBlur;

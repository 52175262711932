import React, { useContext, useEffect, useState } from 'react';
import { PluginContext } from '../stores/PluginContext';
import { LocalStream } from '@100mslive/hmsvideo-web';
import LockIcon from 'mdi-react/LockIcon';
import Layout from '../components/Plugins/Layout';
import ExcalidrawApp, { generateExcaliLink } from '@100mslive/hms-excalidraw';
import ContainerDimensions from 'react-container-dimensions';
import { closeMediaStream } from '../utils';

export const initWhiteBoard = (setOptions, addBoard) => {
  let whiteboardURL =
    process.env.WHITEBOARD_URL || 'https://whiteboard.100ms.live';
  const roomOptions = generateExcaliLink(whiteboardURL);
  setOptions({ ...roomOptions });
  addBoard(roomOptions);

  return roomOptions;
};

export default function WhiteBoard() {
  const {
    client,
    options,
    userPluginOwner,
    setOptions,
    prevBoards,
  } = useContext(PluginContext);
  const { roomId, roomKey, isLocked } = options || {};
  const isOwner = userPluginOwner();
  const [canvasStream, setCanvasStream] = useState(null);

  useEffect(() => {
    if (userPluginOwner()) {
      setTimeout(() => {
        const canvas = document.querySelector('#canvas');
        const canvasMediaStream = canvas.captureStream();

        const getSilentTrack = () => {
          let ctx = new AudioContext(),
            oscillator = ctx.createOscillator();
          let dst = oscillator.connect(ctx.createMediaStreamDestination());
          oscillator.start();
          return Object.assign(dst.stream.getAudioTracks()[0], {
            enabled: false,
          });
        };

        canvasMediaStream.addTrack(getSilentTrack());
        const stream = new LocalStream(
          canvasMediaStream,
          {
            codec: 'VP8',
            resolution: 'hd',
            bandwidth: 512,
            screen: true,
            description: 'IGNORE',
          },
          { video: true, audio: false }
        );
        setCanvasStream(stream);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (userPluginOwner() && canvasStream) {
      client.publish(canvasStream, client.rid, () =>
        console.log('Error publishing canvas stream.')
      );

      const cleanUp = () => {
        console.log('Clean up whiteboard');
        client.unpublish(canvasStream, client.rid);
        closeMediaStream(canvasStream);
      };

      window.addEventListener('beforeunload', cleanUp);

      return () => {
        window.removeEventListener('beforeunload', cleanUp);
        cleanUp();
      };
    }
  }, [canvasStream]);

  return (
    <Layout
      headElement={
        <div>
          {isOwner ? (
            <div style={{ display: 'flex' }}>
              WhiteBoard{' '}
              {prevBoards.findIndex(board => board.roomId == roomId) + 1}
              <button
                title="Lock Editing By Others"
                className={`w-6 h-6 ${
                  isLocked ? 'bg-red-500' : 'bg-gray-800'
                } bg-opacity-50 hover:bg-indigo-500 rounded flex items-center justify-center`}
                onClick={() => setOptions({ isLocked: !isLocked })}
              >
                <LockIcon className="w-4 h-4 text-white" />
              </button>
            </div>
          ) : (
            isLocked && (
              <button
                title="Lock Editing By Others"
                className="w-6 h-6  bg-red-500 bg-opacity-50 rounded flex items-center justify-center"
              >
                <LockIcon className="w-4 h-4 text-white" />
              </button>
            )
          )}
        </div>
      }
    >
      {roomId && (
        <div style={{ maxHeight: '97%', width: '100%' }}>
          <ContainerDimensions>
            {({ width, height }) => {
              return (
                <>
                  {/* <iframe
                  src={roomLink}
                  frameBorder="0"
                  style={{ width: '100%', height: '100%' }}
                ></iframe> */}
                  <ExcalidrawApp
                    roomId={roomId}
                    roomKey={roomKey}
                    componentProps={{
                      width: width,
                      height: height,
                      scrollViewport: false,
                    }}
                    firebase_config={process.env.REACT_APP_FIREBASE_CONFIG}
                  />
                  {isLocked && !isOwner && (
                    <div
                      style={{
                        background: 'transparent',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                      }}
                    ></div>
                  )}
                </>
              );
            }}
          </ContainerDimensions>
        </div>
      )}
    </Layout>
  );
}

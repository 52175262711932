import React, { useContext } from 'react';
import { PluginContext } from '../../stores/PluginContext';

export default function Layout({ headElement, children }) {
  const {
    name: activePlugin,
    owner,
    userPluginOwner,
    availablePlugins,
  } = useContext(PluginContext);

  const { icon: ActivePluginIcon, label: pluginLabel } =
    activePlugin &&
    availablePlugins.find(plugin => plugin.name == activePlugin);
  return (
    <div className="w-full max-w-full h-full max-h-full flex flex-col justify-center items-center pb-1 px-4 rounded-lg">
      <div className="w-full flex justify-between items-center text-white text-base mb-2 ">
        <div className="flex items-center">
          <ActivePluginIcon className="inline mr-1" />
          <span className="mr-1">{pluginLabel}:</span>
          {userPluginOwner()
            ? 'You are presenting'
            : `${owner.name} is presenting`}
        </div>
        {headElement && <div>{headElement}</div>}
      </div>
      <div className="flex-1 w-full">
        <div className="w-full h-full flex justify-center items-center relative">
          {children}
        </div>
      </div>
    </div>
  );
}

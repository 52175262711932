import React, { useEffect, useRef, useState } from 'react';
import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';
import { setVirtualBackground } from '@100mslive/hms-virtual-background';

// import useInterval from '../hooks/useInterval';
// import { findRTPFromTransport } from '../utils/webrtc-stats';

export default function LocalVideoView({
  id,
  client,
  stream,
  label,
  audioMuted,
  videoMuted,
  isBlur,
  isBlurImage,
  blurImage,
  videoType,
  onPin,
  onUnpin,
  pinned = false,
}) {
  // console.log("audioMuted state =", audioMuted);
  const [minimize, setMinimize] = useState(false);
  const [blurredStream, setBlurredStream] = useState(stream);
  // const [audioLevelLabel, setAudioLevelLabel] = useState(0);
  // const [audioLevelCircleSize, setAudioLevelCircleSize] = useState(0);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  let localLabel = (videoType === 'localVideo' && label + '(You)') || label;

  useEffect(() => {
    videoRef.current.srcObject = blurredStream;
    console.log('INSIDE VIDEOREF USEEFFECT');
    console.log('BLUR INFO IS ', isBlur, isBlurImage, blurImage);
    return () => {
      videoRef.current.srcObject = null;
    };
  }, [videoRef, blurredStream]);

  useEffect(() => {
    console.log('INSIDE BLURVIEW USEEFFECT LOCALVIDEOVIEW');
    console.log('BLURINFO ', isBlur, isBlurImage, blurImage);
    if (videoType !== 'localScreen') {
      if (isBlur) {
        console.log('INSIDE BLUR IS ON');
        if (isBlurImage) {
          console.log('CHANGING BACKGROUND IMAGE');
          setBlurredStream(
            setVirtualBackground({ background: blurImage, localStream: stream })
          );
        } else {
          console.log('CHANGING BACKGROUND BLUR');
          setBlurredStream(
            setVirtualBackground({ background: 'blur', localStream: stream })
          );
        }
      } else {
        console.log('INSIDE BLUR IS OFF');
        setBlurredStream(
          setVirtualBackground({ background: 'default', localStream: stream })
        );
      }
    }
  }, [isBlur, isBlurImage, blurImage]);

  // useInterval(() => {
  //   if (stream === null || stream === undefined || videoType === 'localScreen')
  //     return;
  //   const statsSource = findRTPFromTransport(
  //     stream.transport,
  //     'audio',
  //     'sender'
  //   );
  //   statsSource.getStats().then(reports => {
  //     reports.forEach(report => {
  //       if (report.type !== 'media-source') return;
  //       const audioLevel = Math.max(0, report.audioLevel || 0);
  //       setAudioLevelLabel(
  //         Math.round((audioLevel + Number.EPSILON) * 100000) / 100000
  //       );

  //       if (containerRef.current) {
  //         const circleWidth = Math.min(24, 48 * Math.sqrt(audioLevel));
  //         setAudioLevelCircleSize(circleWidth);
  //       }
  //     });
  //   });
  // }, 300);

  // const onMinimizeClick = () => {
  //   setMinimize(!minimize);
  // };

  let minIconStyle = 'local-video-icon-layout';
  if (videoType == 'localVideo') {
    minIconStyle = 'local-video-min-layout';
  }

  return (
    <div
      ref={containerRef}
      className={`local-${
        videoType === 'localVideo' ? 'video' : 'screen'
      }-container w-full max-w-full h-full max-h-full flex justify-center items-center relative p-1`}
    >
      {videoMuted && (
        <div className="absolute left-0 right-0 mx-auto text-center z-10">
          <img
            className="inline rounded-full"
            src={`https://ui-avatars.com/api/?background=random&name=${label}`}
            alt={localLabel}
          />
        </div>
      )}
      <video
        ref={videoRef}
        id={id}
        autoPlay
        playsInline
        muted={true}
        style={{ display: `${minimize ? 'none' : ''}` }}
        className={`h-full rounded shadow-lg`}
      />
      <div
        className={`${minimize ? minIconStyle : 'local-video-icon-layout'}`}
      ></div>
      <div className="absolute top-0 right-0 pt-2 w-full justify-center flex items-center">
        <span
          className="px-3 bg-indigo-900 rounded text-white inline-block bg-opacity-75 h-6 inline-block mr-0.5"
          style={{ lineHeight: '1.5rem' }}
        >
          {localLabel}
        </span>
        {onPin && (
          <button
            className="w-6 h-6 bg-gray-800 bg-opacity-50 hover:bg-indigo-500 rounded flex items-center justify-center ml-0.5"
            onClick={onPin}
          >
            <PinIcon className="w-4 h-4 text-white" />
          </button>
        )}
        {onUnpin && (
          <button
            className="w-6 h-6 bg-red-500 bg-opacity-50 hover:bg-red-600 rounded flex items-center justify-center ml-0.5"
            onClick={onUnpin}
          >
            <PinOffIcon className="w-4 h-4 text-white" />
          </button>
        )}
        {/* <span
          className={`mx-3 bg-indigo-900 `}
          style={{
            // Margin such that the circle do not shift label views
            margin: `${(24 - audioLevelCircleSize) / 2 + 4}px`,
            width: `${audioLevelCircleSize}px`,
            height: `${audioLevelCircleSize}px`,
            borderRadius: '50%',
          }}
        />
        <span
          className="px-3 bg-indigo-900 rounded text-white inline-block bg-opacity-75 h-6 inline-block mr-0.5"
          style={{ lineHeight: '1.5rem' }}
        >
          {`AudioLevel ${audioLevelLabel}`}
        </span> */}
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import { PluginContext } from '../../stores/PluginContext';
import { LocalVideoView, MainVideoView, SmallVideoView } from '../../videoview';

const ActivePlugin = React.memo(({ plugin }) => {
  const { availablePlugins } = useContext(PluginContext);
  const PluginComponent = availablePlugins.find(
    newPlugin => newPlugin.name == plugin
  ).component;

  return <PluginComponent />;
});

const Pinned = ({
  id,
  client,
  localScreen,
  localStream,
  audioMuted,
  videoMuted,
  streams,
  onUnpin,
  pinned,
  plugin,
  loginInfo,
  onRequest,
  isBlur,
  isBlurImage,
  blurImage,
}) => {
  let isLocalStreamPinned, isLocalScreenPinned, pinnedStream, newStreams;
  if (plugin) {
    isLocalStreamPinned = false;
    isLocalScreenPinned = false;
    pinnedStream = null;
    newStreams = streams;
  } else {
    isLocalScreenPinned = localScreen && pinned === id + '-screen';
    isLocalStreamPinned = localStream && pinned === id + '-video';
    [pinnedStream] = streams.filter(s => s.sid === pinned);
    newStreams = streams.filter(s => s.sid !== pinned);
  }

  return (
    <div
      className={`relative top-0 bottom-0 w-full flex justify-between`}
      style={{ height: 'calc(100vh - 128px)', backgroundColor: '#0B0F15' }}
    >
      <div className="w-4/5 h-full max-w-full">
        {plugin ? (
          <ActivePlugin key={`${plugin}`} plugin={plugin} />
        ) : pinned ? (
          <>
            {isLocalStreamPinned && (
              <LocalVideoView
                id={id + '-video'}
                label={`${loginInfo.displayName} (You)`}
                client={client}
                stream={localStream}
                audioMuted={audioMuted}
                videoMuted={videoMuted}
                pinned
                videoType="localVideo"
                onUnpin={() => {
                  onUnpin();
                }}
                isBlur={isBlur}
                isBlurImage={isBlurImage}
                blurImage={blurImage}
              />
            )}

            {isLocalScreenPinned && (
              <LocalVideoView
                id={id + '-screen'}
                label="Your Screen"
                client={client}
                stream={localScreen}
                audioMuted={audioMuted}
                pinned
                videoMuted={videoMuted}
                videoType="localScreen"
                onUnpin={() => {
                  onUnpin();
                }}
              />
            )}
            {pinnedStream && (
              <MainVideoView
                key={pinnedStream.mid}
                id={pinnedStream.mid}
                stream={pinnedStream.stream}
                pinned
                onUnpin={onUnpin}
                audioEnabled={pinnedStream.audioEnabled}
                videoEnabled={pinnedStream.videoEnabled}
                screenshare={pinnedStream.screenshare}
                uid={pinnedStream.uid}
                onRequest={onRequest}
              />
            )}
          </>
        ) : null}
      </div>

      <div className={`w-1/5 max-h-full overflow-y-auto mx-auto`}>
        {newStreams.map((item, index) => (
          <div key={`stream-${index}`} className="w-full">
            <SmallVideoView
              key={item.mid}
              id={item.mid}
              stream={item.stream}
              audioEnabled={item.audioEnabled}
              videoEnabled={item.videoEnabled}
              screenshare={item.screenshare}
              label={item.stream.info.name}
            />
          </div>
        ))}

        {localScreen && !isLocalScreenPinned && (
          <div className="w-full">
            <SmallVideoView
              id={id + '-screen'}
              stream={localScreen}
              audioEnabled={true}
              videoEnabled={true}
              label="Your Screen"
              isMuted={true}
            />
          </div>
        )}

        {localStream && !isLocalStreamPinned && (
          <div className="w-full">
            <SmallVideoView
              id={id + '-video'}
              stream={localStream}
              audioEnabled={!audioMuted}
              videoEnabled={!videoMuted}
              isLocal={true}
              label={loginInfo.displayName}
              isMuted={true}
              isBlur={isBlur}
              isBlurImage={isBlurImage}
              blurImage={blurImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { Pinned };

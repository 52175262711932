import React, { useContext, useEffect, useState } from 'react';
import GooglePicker from 'react-google-picker';
import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { PluginContext } from '../stores/PluginContext';
import Layout from '../components/Plugins/Layout';

function GoogleDrivePickerButton({ setOptions }) {
  const handleChange = ({ action, ...data }) => {
    if (action == 'picked' && data.docs) {
      console.log('Selected Data', data);
      setOptions(data.docs[0]);
    }
  };

  return (
    <GooglePicker
      clientId={process.env.GOOGLE_DRIVE_CLIENT_ID}
      developerKey={process.env.GOOGLE_PICKER_API_KEY}
      scope={['https://www.googleapis.com/auth/drive.readonly']}
      onChange={handleChange}
      onAuthenticate={token => console.log('oauth token:', token)}
      onAuthFailed={data => console.log('on auth failed:', data)}
      multiselect={true}
      navHidden={true}
      authImmediate={false}
      // mimeTypes={["image/png", "image/jpeg", "image/jpg"]}
      viewId={'DOCS'}
    >
      <Button color="primary">Open File Picker</Button>
    </GooglePicker>
  );
}

function DriveFormDialog({ disabled, setOptions }) {
  const [currDoc, setCurrDoc] = useState(null);

  const handleSubmit = () => {
    setOptions({ url: currDoc });
  };

  return (
    <div>
      <Card>
        <DialogTitle id="form-dialog-title">Google Drive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please paste the <b>Google Drive link</b> of the <b>public</b> file
            you wish to share.
            {/* or choose a file from your Drive by clicking "Choose File" */}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Google Drive File URL"
            type="text"
            fullWidth
            required
            onChange={event => setCurrDoc(event.target.value)}
            disabled={disabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" disabled={disabled}>
            Submit
          </Button>
          {!disabled && <GoogleDrivePickerButton setOptions={setOptions} />}
        </DialogActions>
      </Card>
    </div>
  );
}

export const initializeDrive = () => {
  console.log('Init Drive');
};

export default function Drive() {
  const { options, userPluginOwner, setOptions } = useContext(PluginContext);
  const { doc } = options || {};

  const setDriveOptions = data => {
    setOptions({ doc: data });
  };

  return (
    <Layout>
      {doc ? (
        <iframe
          src={doc.url}
          frameBorder="0"
          style={{ width: '100%', height: '100%' }}
        ></iframe>
      ) : (
        <DriveFormDialog
          setOptions={setDriveOptions}
          disabled={!userPluginOwner()}
        />
      )}
    </Layout>
  );
}

import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../components/Plugins/Layout';
import { PluginContext } from '../stores/PluginContext';
const YTPlayer = require('yt-player');

function YoutubeFormDialog({ disabled, setOptions }) {
  const [currUrl, setCurrUrl] = useState(null);
  const [urlError, setUrlError] = useState(null);

  const handleSubmit = () => {
    let videoId = '';
    const isUrlValid = url => {
      const validYoutubeURL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return url.match(validYoutubeURL) ? RegExp.$1 : false;
    };

    if (!isUrlValid(currUrl)) {
      setUrlError('Please enter a valid YouTube video link!');
      return;
    }
    videoId = new URL(currUrl).searchParams.get('v');

    setOptions({ videoId });
  };

  return (
    <div>
      <Card>
        <DialogTitle id="form-dialog-title">YouTube</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please paste the <b>Youtube link</b> of the video you wish to share.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Youtube Video URL"
            type="text"
            fullWidth
            required
            onChange={event => setCurrUrl(event.target.value)}
            error={Boolean(urlError)}
            helperText={urlError}
            disabled={disabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" disabled={disabled}>
            Submit
          </Button>
        </DialogActions>
      </Card>
    </div>
  );
}

export const initializeYoutube = () => {
  console.log('Init Youtube');
};

export default function Youtube() {
  const [player, setPlayer] = useState(null);
  const {
    client,
    userPluginOwner,
    options,
    setOptions,
    closePlugin,
  } = useContext(PluginContext);
  const { videoId } = options || {};

  useEffect(() => {
    if (player) {
      return () => player.destroy();
    }
  }, [player]);

  useEffect(() => {
    if (videoId) {
      const player = new YTPlayer('#player', {
        width: '100%',
        height: '100%',
        controls: userPluginOwner(),
        modestBranding: true,
        related: false,
        timeupdateFrequency: 2000,
        autoplay: true,
      });

      setPlayer(player);
      player.load(videoId);
      player.setVolume(60);
      player.play();

      const setEventOptions = eventOptions => {
        const isMuted = player && player.isMuted();
        setOptions({
          ...eventOptions,
          isMuted,
        });
      };

      if (userPluginOwner()) {
        player.on('playing', async () => {
          setEventOptions({
            videoState: 'playing',
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('unstarted', async () => {
          setEventOptions({
            videoState: 'unstarted',
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('paused', async () => {
          setEventOptions({
            videoState: 'paused',
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('buffering', async () => {
          setEventOptions({
            videoState: 'buffering',
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('cued', async () => {
          setEventOptions({
            videoState: 'cued',
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('playbackRateChange', async playbackRate => {
          setEventOptions({
            videoState: 'playBackRateChange',
            playbackRate,
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('timeupdate', async seconds => {
          setEventOptions({
            videoState: player.getState(),
            videoTime: player.getCurrentTime(),
            videoSyncTimestamp: Date.now(),
          });
        });

        player.on('ended', () => {
          closePlugin();
        });
      } else {
        client.on('broadcast', async (room, peer, message) => {
          console.log('[Youtube] broadcast: ', room, peer.name, message);
          const {
            videoState,
            videoTime,
            videoSyncTimestamp,
            isMuted,
          } = message.options;

          if (isMuted != player.isMuted()) {
            if (isMuted) player.mute();
            else player.unMute();
          }

          if (videoState == 'playing') {
            let cur = Date.now();
            let time = videoTime + (cur - videoSyncTimestamp) / 1000;
            let diff = Math.abs(player.getCurrentTime() - time);
            if (diff > 0.5) player.seek(time);
            player.play();
          } else if (
            videoState == 'paused' ||
            videoState == 'buffering' ||
            videoState == 'unstarted' ||
            videoState == 'cued'
          ) {
            player.pause();
          } else if (videoState == 'playBackRateChange') {
            player.setPlaybackRate(message.options.playbackRate);
          }
        });
      }
    }
  }, [videoId]);

  return (
    <Layout>
      {videoId ? (
        <div
          id="player"
          style={!userPluginOwner() ? { pointerEvents: 'none' } : {}}
        ></div>
      ) : (
        <YoutubeFormDialog
          setOptions={setOptions}
          disabled={!userPluginOwner()}
        />
      )}
    </Layout>
  );
}

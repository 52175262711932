import React, { useEffect, useState } from 'react';
import { initializeMiroBoard } from '../plugins/MiroBoard';
import Drive from '../plugins/Drive';
import WhiteBoard, { initWhiteBoard } from '../plugins/WhiteBoard';
import Youtube from '../plugins/Youtube';

import DrawingIcon from 'mdi-react/DrawIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import GoogleDriveIcon from 'mdi-react/GoogleDriveIcon';

const availablePlugins = [
  {
    name: 'WHITEBOARD',
    label: 'Whiteboard',
    icon: DrawingIcon,
    component: WhiteBoard,
  },
  {
    name: 'YOUTUBE',
    label: 'YouTube',
    icon: YoutubeIcon,
    component: Youtube,
  },
  {
    name: 'DRIVE',
    label: 'Google Drive',
    icon: GoogleDriveIcon,
    component: Drive,
  },
];

const PluginContext = React.createContext();

export default function PluginContextProvider({ client, children }) {
  const [activePlugin, setActivePlugin] = useState({
    name: null,
    owner: { uid: null, name: null },
  });
  const [options, setOptions] = useState(null);
  const [prevBoards, setBoards] = useState([]);

  useEffect(() => {
    if (options) broadcastPlugin();
  }, [options]);

  useEffect(() => {
    if (client) {
      client.on('broadcast', handleBroadcast);
      client.on('stream-add', broadcastPlugin);
      client.on('stream-remove', handleStreamRemove);
      if (new Map(JSON.parse(localStorage.getItem('boards'))).get(client.rid))
        setBoards(
          new Map(JSON.parse(localStorage.getItem('boards'))).get(client.rid)
        );

      return () => {
        client.off('broadcast', handleBroadcast);
        client.off('stream-add', broadcastPlugin);
        client.off('stream-remove', handleStreamRemove);
      };
    }
  }, [client, activePlugin, options]);

  const addBoard = newBoard => {
    if (
      !prevBoards.find(board => board.roomId == newBoard.roomId) &&
      userPluginOwner()
    ) {
      setBoards([...prevBoards, newBoard]);
      let map = new Map(JSON.parse(localStorage.getItem('boards')));
      map.set(client.rid, [...prevBoards, newBoard]);
      localStorage.setItem('boards', JSON.stringify(Array.from(map.entries())));
    }
  };

  const removeBoards = board => {
    if (board) {
      //a board is provided remove it

      let boards = prevBoards.filter(a => a.url !== board.url);

      setBoards(boards);
      let map = new Map(JSON.parse(localStorage.getItem('boards')));
      map.set(client.rid, boards);
      localStorage.setItem('boards', JSON.stringify(Array.from(map.entries())));
    } else {
      //else remove all boards
      setBoards([]);
      let map = new Map(JSON.parse(localStorage.getItem('boards')));
      map.set(client.rid, []);
      localStorage.setItem('boards', JSON.stringify(Array.from(map.entries())));
    }
  };

  const handleBroadcast = (room, peer, message) => {
    console.log('[Plugin] broadcast: ', room, peer.name, message);
    if (message.type == 'PLUGIN') {
      setActivePlugin(message.activePlugin);
      setOptions(message.options);
    }

    if (message.type == 'PLUGIN_CLOSE') {
      setActivePlugin({ name: null, owner: { uid: null, name: null } });
      setOptions(null);
    }
  };

  const handleStreamRemove = (room, peer, streamInfo) => {
    if (peer.uid == activePlugin.owner.uid) {
      setActivePlugin({ name: null, owner: { uid: null, name: null } });
      setOptions(null);
    }
  };

  const closePlugin = () => {
    if (userPluginOwner()) {
      setActivePlugin({ name: null, owner: { uid: null, name: null } });
      setOptions(null);
      client.broadcast(
        {
          senderName: client.peer.userName,
          msg: { type: 'PLUGIN_CLOSE' },
        },
        client.rid
      );
    }
  };

  const userPluginOwner = () => {
    if (client && activePlugin.name && activePlugin.owner.uid)
      return activePlugin.owner.uid == client.uid;
    else if (client && !activePlugin.name && !activePlugin.owner.uid)
      return true;
    else return false;
  };

  const initializePlugin = name => {
    switch (name) {
      case 'MIRO':
        initializeMiroBoard();
        break;
      case 'WHITEBOARD':
        initWhiteBoard(setOptions, addBoard);
        break;
    }
  };

  const broadcastPlugin = () => {
    activePlugin.name &&
      userPluginOwner() &&
      client.broadcast(
        {
          senderName: client.peer.userName,
          msg: { type: 'PLUGIN', activePlugin, options },
        },
        client.rid
      );
  };

  const togglePlugin = name => {
    if (activePlugin.name == name) {
      closePlugin();
    } else {
      setActivePlugin({
        name,
        owner: { uid: client.uid, name: client.peer.userName },
      });
      initializePlugin(name);
    }
  };

  const setActivePluginInfo = name => {
    setActivePlugin({
      name,
      owner: { uid: client.uid, name: client.peer.userName },
    });
  };

  return (
    <PluginContext.Provider
      value={{
        client,
        availablePlugins,
        name: activePlugin.name,
        owner: activePlugin.owner,
        options,
        setOptions: newOptions => {
          setOptions({ ...options, ...newOptions });
        },
        togglePlugin,
        userPluginOwner,
        closePlugin,
        setActivePluginInfo,

        prevBoards,
        addBoard,
        removeBoards,
      }}
    >
      {children}
    </PluginContext.Provider>
  );
}

export { PluginContext, PluginContextProvider };

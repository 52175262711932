import React from 'react';

const ControlButton = ({
  icon,
  label,
  activeIcon,
  isActive,
  onClick,
  disabled = false,
  permission = true,
}) => {
  return (
    <button
      className="flex flex-col justify-between items-center w-12"
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`w-10 h-10 rounded-lg flex items-center justify-center transition duration-100 ease-in ${
          permission
            ? isActive
              ? 'bg-red-500 hover:bg-red-500 hover:text-red-100'
              : 'bg-indigo-900 hover:bg-red-500 hover:text-red-100'
            : 'bg-gray-100 bg-opacity-60'
        }`}
      >
        {isActive ? activeIcon : icon}
      </div>
      <div className="pt-1">
        <span className="text-xs text-indigo-100">{label}</span>
      </div>
    </button>
  );
};

export { ControlButton };
